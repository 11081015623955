/* .table-scroll-wrapper {
    overflow: auto;
    border: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll {
    margin: 0px;
    border: none;
}

.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-scroll tr td:first-child,
.table-scroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  width:120px;
  left: 0;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll th:first-child {
    z-index: 4;
}

.table-scroll tr td:nth-child(2),
.table-scroll th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  width: 120px;
  left: 110px;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll th:nth-child(2) {
    z-index: 4;
}


.table-scroll tr td:nth-child(3),
.table-scroll th:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  left: 210px;
  z-index: 2;
  border-right: var(--border-size-s) solid var(--color-neutral-4);
}

.table-scroll th:nth-child(3) {
    z-index: 4;
}

.table-scroll tr td:last-child {
  border-right: none;
} */


table{
    width: "100%";
    margin: "0";
    padding: "0";
    background-color: #fff;
    border-collapse: collapse;
    border-spacing: "0";
    
}
th {
   z-index: 1;
   /* background-color: #008081; */
   /* color: white; */
    /* position: sticky; */
    top: 0; 
    /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.2);
    /* white-space: nowrap */
  }
  
.App {
  text-align: center;
  font-family: 'Montserrat';
  background: #f2f2f2;
  min-height: 98vh;

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.spider-leg-container .spider-leg-pin {
  position: relative;
  width: 34px;
  height: 34px;
  margin-left: -5px;
  margin-top: -24px;
  background-image: url(https://api.mapbox.com/styles/v1/mapbox/dark-v9/sprite.png?access_token=pk.eyJ1Ijoic29jcmF0YSIsImEiOiJjaXJxc2wzam0waGU5ZmZtODhqd2ttamdxIn0.1ZQEByXoDD7fGIa9lUHIqg);
  background-position: -110px -110px;
}
.spider-leg-container .spider-leg-line {
  background-color: #f4f4f4;
  width: 3px;
  transform-origin: bottom;
  margin-left: 10px;
}
.spider-leg-container:hover .spider-leg-line {
  background-color: #f404f4;
}
#marker {
  background-image: url("https://docs.mapbox.com/mapbox-gl-js/assets/washington-monument.jpg");
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.mapboxgl-popup {
  max-width: 200px;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contentContainer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

/* ::-webkit-scrollbar {
  width: 5px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1;
}


::-webkit-scrollbar-thumb {
  background: #888;
}


::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
.pac-container { z-index: 100000 !important; }

.MuiTypography-root .MuiTypography-body1 .MuiFormControlLabel-label  {
  font-size: 0.75rem;
}

#states-autocomplete{
  border: none;
  border-bottom: 1px solid #8a8888;
  width: 100%;
  padding:6px;
  font-size: 16px;
}

/* AG grid css options */
.ag-cell-focus,.ag-cell-no-focus{
  border:none !important;
}

.ag-theme-material, .ag-paging-panel{
  border-top: none !important;
}

.ag-row-selected {
  background-color: rgba(180, 203, 218, 0.63)!important;
}

/* .ag-row :hover{
  background-color: pink;
} */

.cell-wrap-text {
  white-space: normal !important;
}

.DateInput_input {
  font-size: 12px !important;
  width: 52% !important;
}

.MuiInput-root {
  text-align: center;
}

/**
This is to show the filter icon on ag-grid by default, instead of making it visible on hover
*/
.ag-header-icon,.ag-header-cell-menu-button {
  opacity: 1!important;
}

/**
This is for setting up min-height of ag grid table when the domlayout is set to autoHeight
In general when the domLayout is set to autoHeight, the min-Height is around 50px only
*/
.ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper, .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
  min-height: 450px!important;
}

/* *
This is for removing padding and boxshadow for ag grid loader image
*/
.ag-theme-material .ag-overlay-loading-center {
  border-radius: 0px!important;
  box-shadow: none!important;
  padding: 0px!important;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #008081 !important;
}


 ::-webkit-scrollbar {
  width: 8px!important;
}
 ::-webkit-scrollbar-track {
  box-shadow:inset 0 0 6px rgba(0,0,0,0.3)!important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(211, 211, 211)!important;
  outline: 1px solid rgb(210, 213, 217)!important;
}

.react-autosuggest__input {
  /* width: 304px; */
  height: 30px;
  background: 'transparent';
  border: 1px solid #868585;
  padding: 5px 20px;
  border-radius: 0px 0px 0px 0px;
  outline: 'none';
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 300;
  opacity: 0.5;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: none;
  position: relative;
  width: 344px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/* These below 4 are for scrolling in full screen */
:fullscreen {
  overflow: scroll !important;
}
::-ms-fullscreen {
  overflow: scroll !important;
}
::-webkit-full-screen {
  overflow: scroll !important;
}
::-moz-full-screen {
  overflow: scroll !important;
}

/**
This is for removing the spinner from textfield input when the type is set to number (for increment and decrement)
*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.slick-prev:before,
.slick-next:before {
  color: rgb(13, 12, 12)!important;
  font-size: 23px;
}

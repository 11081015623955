.MuiInputBase-root-MuiInput-root {
font-size: 20px;
}
.css-1yy0aak-MuiTypography-root.Mui-selected  {
    background-color: #008081;
}
.MuiTypography-colorTextSecondary {
    color: #A0ACC3 !important;
  }
  .MuiTypography-colorTextPrimary {
    color : #144183 !important
  }
  button[class*="MuiTypography-root.Mui-selected "] {
   background-color: #008081;
  } 
  
  div[class*="materialui-daterange-picker-MuiSelect-select"] ,div[class*="materialui-daterange-picker-MuiSelect-selectMenu"] {
    color : #144183 !important;
  }
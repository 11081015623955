.cell-violations {
   
  background-color: #f30a0a66;
}

.cell-noviolations {
  
  background-color:none;
}
.cell-warnings {
  background-color: #f9ad5df8;
}
.cell-nowarnings {
  
  background-color:none;
}

/* .CalendarDay__selected_span {
    background: #e0b282;
    color: white;
    border: 1px solid $light-red; 
  } */
  .DateRangePickerInput {
    background: #f2f2f2;
    color: #008081;
    /* border: 1px solid $light-red;  */
  }
  .DateInput_1{
      border-radius: 22px;
      background-color: #fff;
      height:2rem;

  }
  .DateInput_input_1{
    /* border-radius: 22px; */
    background-color: #fff;
    height:0.5rem;
}
.DateRangePickerInput__withBorder{
  border: none;
}
.DateRangePickerInput_calendarIcon_svg_1 {
  /* background:#008081; */
  color: #008081;
  border: 1px solid $light-red; 
  height:1.5rem;
  width:1.5rem;
}
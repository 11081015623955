.custom-autocomplete  .css-6h7wko-MuiAutocomplete-root .MuiOutlinedInput-root {
   padding: 0px !important;

  }

  /* .css-11imnd8 .MuiOutlinedInput-root {
    padding: 0px !important;
  } */

/* .css-6h7wko-MuiAutocomplete-root .MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
    padding: 0px;
  } */
